import React from 'react'
import '../assets/scss/main.scss'
import moment from 'moment';

class Comment extends React.Component {

  constructor(props) {
    super(props);
    this.renderDateTime = this.renderDateTime.bind(this);
  }

  renderDateTime(momentObj) {
    return momentObj.format('MMMM Do YYYY, h:mm:ss a');
  }

  render() {
    return (
    <div>
      <span>{this.props.author}</span><span style={{fontSize:"12px"}}> {this.renderDateTime(this.props.commentDate)}</span>
      <br />
      <p style={{marginLeft:"25px"}}>{this.props.comment}</p>
    </div>
    );

  }

}

export default Comment
