import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Comment from '../components/comment'

import moment from 'moment';


class HomeIndex extends React.Component {
  
  constructor(props) {
    super(props);
    this.fetchComments = this.fetchComments.bind(this);
    const comments = []
        
    this.state = {
      comments: comments,
      currentCommentText: '',
      currentCommentAuthor: ''

    }; 
    this.renderComments = this.renderComments.bind(this);
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleCommentTextChange = this.handleCommentTextChange.bind(this);
    this.handleCommentSubmission = this.handleCommentSubmission.bind(this);
  }

  async componentDidMount() {
    const comments = await this.fetchComments() 
    this.setState({comments: comments});
  }

  async fetchComments() {
    const response = await fetch('/api/commentsapi', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const unconvertedComments = await response.json()
    console.log(`unconverted: ${unconvertedComments}`)
    const comments = []
    unconvertedComments.forEach(function(unconvertedComment) {
      const parsedUnconvertedComment = JSON.parse(unconvertedComment)
      console.log(parsedUnconvertedComment)
      comments.push(
        {
          text: parsedUnconvertedComment.text,
          author: parsedUnconvertedComment.author,
          commentDate: moment(parsedUnconvertedComment.commentDate)
        }
      )
    });
    comments.sort(function(a, b) {
      return b.commentDate - a.commentDate
    });
    console.log(`converted: ${JSON.stringify(comments)}`)
    return comments;
  }

  renderComments() {
    const comments = this.state.comments.map(function(comment) {
      return <Comment key={comment.text} comment={comment.text} commentDate={comment.commentDate} author = {comment.author}/>
    });
    return (
      comments
    );
  }

  handleAuthorChange(event) {
    this.setState({currentCommentAuthor: event.target.value});
  }

  handleCommentTextChange(event) {
    this.setState({currentCommentText: event.target.value});
  }

  async handleCommentSubmission(event) {
    event.preventDefault();
    const newComment =  {
      text: this.state.currentCommentText,
      author: this.state.currentCommentAuthor,
      commentDate: moment()
    }
    const newCommentToSend = JSON.stringify({
      'commentJsonText':
      {
        text: newComment.text,
        author: newComment.author,
        commentDate: newComment.commentDate.valueOf()
      }
    });
    const response = await fetch('/api/commentsapi', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: newCommentToSend // body data type must match "Content-Type" header
    });

    const newComments = this.state.comments.concat(newComment)
    newComments.sort(function(a, b) {
      return b.commentDate - a.commentDate
    });
    this.setState({comments: newComments});
  }
 
  render() {
    const siteTitle = 'Mommy Shin'
    const siteDescription = ''
 
    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                Mommy Shin
              </h2>
            </header>
            <p>
              Accumsan orci faucibus id eu lorem semper. Eu ac iaculis ac nunc
              nisi lorem vulputate lorem neque cubilia ac in adipiscing in curae
              lobortis tortor primis integer massa adipiscing id nisi accumsan
              pellentesque commodo blandit enim arcu non at amet id arcu magna.
              Accumsan orci faucibus id eu lorem semper nunc nisi lorem vulputate
              lorem neque cubilia.
            </p>
          </section>

          <section id="two" style={{paddingTop:"3em", marginTop:"3em"}}>
            <Gallery />
          </section>

          <section id="three" style={{paddingTop:"3em", marginTop:"1em"}} >
            <h2>
              Leave a comment
            </h2>
            <div className="row">
              <div className="12u">
                <form method="post" action="#">
                  <div className="row uniform 100%">
                    <div className="8u">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={this.state.currentCommentAuthor}
                        onChange={this.handleAuthorChange}
                      />
                    </div>
                    <div className="8u" style={{paddingTop:10}}>
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Message"
                        rows="4"
                        value={this.state.currentCommentText}
                        onChange={this.handleCommentTextChange}
                      ></textarea>
                    </div>
                  </div>
                  <ul className="actions" style={{ marginTop: 10 }}>
                    <li>
                      <input type="submit" value="Send Message" onClick={this.handleCommentSubmission} disabled={this.state.currentCommentText.length == 0 || this.state.currentCommentAuthor.length == 0}/>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </section>
          {this.renderComments()}
        </div>
      </Layout>
    );
  }
}

export default HomeIndex
